import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';

export default function BlogIndexPage() {
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: 'url("/img/home-jumbotron.jpg")',
          background: 'linear-gradient(0deg, rgba(0,0,0,.7), rgba(0,0,0,.3)), url("/img/home-jumbotron.jpg")',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'bottom left',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            color: 'white',
            padding: '1rem',
          }}
        >
          Blog
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll />
          </div>
        </div>
      </section>
    </Layout>
  );
}
