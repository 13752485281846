import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { borderRadius: '5px' };
  const { alt = '', childImageSharp, image } = imageInfo;

  console.log(`imageInfo ${JSON.stringify(imageInfo)}`);

  if (image?.childImageSharp) {
    return (
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        style={imageStyle}
        alt={alt} />
    );
  }

  if (childImageSharp) {
    return (
      <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      style={imageStyle}
      alt={alt} />
    );
  }

  if (image && typeof image === 'string') {
    return <img style={imageStyle} src={image} alt={alt} />;
  }

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
